.modal-head {
    /* text-align: center; */
    /* height: 40px; */
    white-space: nowrap;
    font-size: 20px;
    font-weight: 500 !important;
    line-height: 1.1;
    /* margin-top: 10px; */
    /* border-bottom: 1px solid #e2e2e2; */
    letter-spacing: 1px;
}

.confirm.modal-head {
    color: #636363;
    font-size: 14px !important;
}

.failure.modal-head {
    color: #f05050;
    font-size: 14px !important;
}

.success.modal-head {
    color: #636363;
    font-size: 14px !important;
}

.modal-body-content {
    /* text-align: center; */
    min-height: 70px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500 !important;
    line-height: 1.1;
    margin-top: 10px;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #e2e2e2; */
}

.confirm.modal-body-content {
    color: #454545;
}

.failure.modal-body-content {
    color: #f05050;
}

.success.modal-body-content {
    color: #454545;
}

.actions-modal-btn {
    border-radius: 5px !important;
    cursor: pointer !important;
    box-shadow: 0 0px 4px rgba(90, 90, 90, 0.42) !important;
    width: 80px !important;
    border: none !important;
    display: inline-block !important;
    font-size: 15px !important;
}

.confirm.actions-modal-btn {
    background-color: var(--pink-primary-color) !important;
    color: #fff !important;
}

.confirm.actions-modal-btn:hover {
    background-color: var(--black5) !important;
}

.actions-modal-btn:focus {
    outline: none !important;
}


/* Comments Modal */

.comments-dialog {
    padding: 2px;
    border: 1px solid var(--black2);
    background-color: var(--grey5) !important;
    max-width: 1000px !important;
    min-width: 750px !important;
    margin: 5px;
    border-radius: 5px;
}

#comments-dialog .MuiDialogTitle-root {
    padding: 0;
}

.comments-modal-header {
    background-color: var(--white1);
    letter-spacing: 0.5px;
    padding: 8px 12px;
    border-bottom: 1px solid var(--black2);
    color: var(--black2);
}

.comments-modal-body {
    margin: 0px -11px;
}

.comments-modal-footer {
    padding: 8px 12px;
    border-top: 1px solid var(--black2);
    color: var(--black2);
    margin-left: -10px;
    margin-right: -10px;
}

#comments-dialog .MuiDialogActions-root {
    display: unset !important;
    padding: unset !important;
    justify-content: unset !important;
}

#comments-dialog .MuiDialogContent-root .comments-thread {
    max-height: 500px;
    overflow: auto;
}

.dialog-body .MuiDialogContent-root {
    overflow-x: hidden;
}

.full-screen>.dialog-body {
    overflow: hidden;
    height: 95vh;
    width: 95vw;
    display: flex;
    flex-direction: column;
}

.full-screen>.dialog-body>.MuiDialogContent-root {
    display: flex;
    flex-direction: column;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content>.toolbar {
    flex: 0;
    display: flex;
    flex-direction: row;
    height: fit-content;
    padding-bottom: 5px;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content>.body {
    overflow: auto;
    flex: 1;
    position: relative;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content>.body>div {
    overflow: hidden;
    display: inline-flex;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content>.body span {
    position: absolute;
    cursor: pointer;
    background-color: rgba(240, 80, 80, 0.2);
    border: solid 1px red;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content>.body canvas {
    position: relative;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content>.body svg {
    position: absolute;
}

.full-screen>.dialog-body>.MuiDialogContent-root>.modal-body-content>.body svg g {
    cursor: pointer;
}

.client-form-sign-confirmation-modal {
    width: 100%;
    white-space: initial;
    line-height: 29px;
    min-width: 900px;
}


.custom-modal-for-edit-df-form {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal-for-edit-df-form .main-box {
    min-width: 500px;
    min-height: 250px;
    background: white;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    border-radius: 5px;
}

.dependents-switch.active .MuiFormControlLabel-label {
    color: #f05050;
}

.popup-table {
    font-size: 14px;
    font-family: 'Manrope';
}

.add-public-key-modal {
    min-width: 600px !important;
    max-width: 100%;
}