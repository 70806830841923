.pagination .MuiSelect-select {
    min-width: max-content !important;
}

#transmission-dashboard-table h1 {
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    color: var(--black6);
}

.home-greeting {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 35px;
}

#select-carriers-plans {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

#select-subgroups-plans {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

.editable-textfield {
    position: relative;
    padding: 5px;
}

#connectivity-requests {
    width: 100%;
}

#connectivity-requests ::-webkit-scrollbar,
#carrier-contacts ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    /* width of the entire scrollbar */
}

#connectivity-requests ::-webkit-scrollbar-thumb,
#carrier-contacts ::-webkit-scrollbar-thumb {
    background-color: var(--grey7disabled);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}

#view-connectivity-requests .input-search {
    background: var(--white2);
    border: 1px solid rgba(30, 135, 240, 0.2);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
}

#connectivity-group-requests-table,
#users-table {
    overflow-x: auto;
    min-width: 100%;
    /* width: max-content; */
}

.download-btn {
    font-size: 35px !important;
    border-radius: 6px !important;
    border: 4px solid var(--pink-primary-color);
    color: var(--pink-primary-color);
    position: absolute;
    right: 115px !important;
    cursor: pointer;
    padding: 2px;
}

.missing-data-warning {
    color: var(--red) !important;
}

.activity-log-list {
    color: var(--black2);
    font-weight: 500;
    font-size: 20px;
}

.activity-log-list li {
    position: relative;
    /* so that pseudoelements are positioned relatively to their "li"s*/
    /* use padding-bottom instead of margin-bottom.*/
    margin-bottom: 0;
    /* This overrides previously specified margin-bottom */
    padding-bottom: 2.5em;
}

.activity-log-list li:after {
    /* bullets */
    width: 15px;
    height: 15px;
    background: var(--pink-primary-color);
    content: "";
    border-radius: 50%;
    position: absolute;
    left: -26px;
    /*adjust manually*/
    top: 8px;
}

.activity-log-list li:before {
    /* lines */
    content: "";
    position: absolute;
    left: -19px;
    /* adjust manually */
    border-left: 1px solid #E6EBF0;
    height: 100%;
    width: 1px;
}

.activity-log-list li:first-child:before {
    /* first li's line */
    top: 12px;
    /* moves the line down so that it disappears under the bullet. Adjust manually */
}

.activity-log-list li:last-child:before {
    /* last li's line */
    height: 6px;
    /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}

.communication-thread .comments-modal-footer {
    border-top: unset;
}

#connect-request-comments .public-key-textarea,
#action-items-comments .public-key-textarea {
    padding: 13px;
}

.communication-thread a {
    color: var(--blue-main-theme);
    font-weight: 500;
}

.communication-thread .cr-comments-actions span {
    color: var(--blue-main-theme);
    padding-right: 20px;
    font-size: 15px;
    cursor: pointer;
}

.action-item-textarea {
    border-radius: 4px;
    min-height: 56px;
    padding: 13px 13px;
    border: 1px solid rgba(0, 0, 0, 0.23);
}

#connect-request-comments .cr-comments-actions span:hover,
#action-items-comments .cr-comments-actions span:hover {
    text-decoration: underline;
}

.cr-comments-div {
    padding: 13px 20px;
    border: 1px solid rgba(204, 204, 204, 0.4);
    background-color: var(--grey5);
    max-width: 100%;
    min-height: 350px;
    max-height: 500px;
    overflow: auto;
    word-break: break-word;
    white-space: pre-line;
}

#create-action-item .MuiFormControl-marginNormal {
    margin-top: 12px !important;
}

.plan-type-modal {
    color: var(--grey2);
    font-weight: 400;
    font-size: 11px;
}

.delete-contact-list-div {
    border: 1px solid var(--grey1-secondary-color);
    box-shadow: 0 0px 4px rgb(90 90 90 / 42%);
    padding: 20px;
}

.status-card {
    border: 2px solid #E5E5E5;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 20px;
}

.status-card-body {
    padding: 10px 0;
    min-height: 100px;
    align-items: center;
    display: inline-grid;
}

.status-card-body h4 {
    color: var(--black2);
    font-weight: 400;
    margin-bottom: 10px;
}

.status-card-footer {
    font-weight: 400;
    font-size: 18px;
    min-height: 35px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.key-box {
    min-width: 20px;
    height: 20px;
    margin-right: 10px;
}

.link-style {
    cursor: pointer;
}

.link-style:hover {
    text-decoration: underline;
}

#feeds-table {
    overflow-x: auto;
    min-width: 100%;
    width: max-content;
}

#view-feeds .input-search {
    background: var(--white2);
    border: 1px solid rgba(30, 135, 240, 0.2);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
}


/* Chrome, Safari, Edge, Opera */

#transmission-config input::-webkit-outer-spin-button,
#transmission-config input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

#transmission-config input[type=number] {
    -moz-appearance: textfield;
}

.scheduler-info-div {
    display: flex;
    background-color: #4e4e4e;
    border-radius: 9px;
    padding: 9px;
    min-height: 45px;
    color: var(--white);
}

.scheduler-table {
    border: 1px solid rgba(0, 0, 0, 0.12);
    min-height: 45px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
}

.scheduler-header {
    font-size: 18px;
    background-color: var(--grey2-secondary-color);
    color: var(--white);
    min-height: 58px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.scheduler-cell {
    color: rgba(0, 0, 0, 0.38);
    min-height: 58px;
    max-height: 58px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.border-table-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#scheduler-frequency ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    /* width of the entire scrollbar */
}

#scheduler-frequency ::-webkit-scrollbar-thumb {
    background-color: var(--grey7disabled);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}

#transmission-dashboard-table .MuiAccordion-root {
    width: 100%;
}

#transmission-dashboard-table .transmission-dashboard-table .MuiAccordionSummary-root {
    padding: 0;
}

.MuiAccordionSummary-content {
    align-items: center;
}

#transmission-dashboard-table .MuiAccordionSummary-content {
    margin: 0;
}

#files-section .MuiAccordionDetails-root,
#user-assignment .MuiAccordionDetails-root,
#select-carrier-plans .MuiAccordionDetails-root {
    width: 100%;
    padding: 0;
    align-items: center;
    display: unset !important;
}

#transmission-dashboard-table .details-header {
    align-items: center;
}

#transmission-dashboard-table .details-full-div {
    box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
}

#customized-dialog .dialog-body {
    /* padding: 2px; */
    /* border: 1px solid var(--black2); */
    /* background-color: var(--grey5) !important; */
    max-width: 1000px !important;
    margin: 5px;
    border-radius: 5px;
    min-width: 350px;
}

#customized-dialog h5 {
    margin-bottom: 15px;
}


/* #customized-dialog p {
    font-size: 15px;
    color: var(--black5);
} */

#maps-table {
    overflow-x: auto;
    min-width: 100%;
    width: max-content;
}

.files-list-div {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    background: #fafafa;
    height: 90%;
}

.forms-list-div {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    background: #fafafa;
    height: 80%;
}

.single-file-div {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 7px 12px;
    width: 100%;
    height: 32px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    margin: 10px 0;
    font-size: 11px;
}

.forms-list {
    max-height: 450px;
    overflow-x: auto;
}

#files-section input {
    padding-top: 8px;
}

#files-section .MuiIconButton-root.Mui-disabled {
    fill: unset;
}

#files-section .MuiIconButton-root:not(.Mui-disabled) .MuiSvgIcon-root {
    fill: var(--pink-primary-color);
}

.components-div {
    border-radius: 4px;
    min-height: 56px;
    padding: 0 13px;
    border: 1px solid var(--grey6);
    overflow-y: auto;
    max-height: 500px;
    font-size: 12px;
}

.components-view-control label {
    margin-bottom: 0 !important;
}

.public-key-textarea {
    width: 100%;
    border: 1px solid rgba(23, 96, 174, 0.25);
    font-size: 14px;
    resize: none !important;
}

.public-key-textarea:focus {
    outline: unset;
}

.dialog-public-key-title {
    color: var(--black1) !important;
    text-decoration: unset !important;
    font-weight: 700 !important;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
}


/* PreMapping Form */

#premapping-form .MuiStepConnector-active,
#premapping-form .MuiStepConnector-completed {
    background-color: var(--pink-primary-color);
    height: 3px;
}

#premapping-form .MuiStepConnector-alternativeLabel {
    top: 20px;
    left: calc(-50% + 22px);
    right: calc(50% + 22px);
}

#premapping-form .active .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel,
#premapping-form .MuiStep-completed .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    background: var(--pink-primary-color);
    color: var(--white1);
    border-radius: 50px;
    padding: 10px;
}

#premapping-form .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    background: var(--grey7disabled);
    color: var(--white1);
    border-radius: 50px;
    padding: 10px;
}

#premapping-form .active .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .25);
}

#premapping-form .MuiStepLabel-label.MuiStepLabel-completed {
    color: var(--pink-primary-color);
}

#premapping-form textarea {
    min-width: 600px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    font-size: 14px;
    resize: none !important;
    margin-left: 9px;
    border-radius: 4px;
}

#premapping-form textarea:focus {
    outline: unset;
}

#premapping-form .Mui-checked {
    color: var(--pink-primary-color);
}

#customer-match-plan .MuiSelect-root {
    border: 1px solid rgba(23, 96, 174, 0.25);
    padding: 10px 8px;
}

#customer-match-plan .MuiPopover-paper {
    position: relative !important;
}

#DF-main-types .MuiSelect-root {
    border: 1px solid rgba(23, 96, 174, 0.25);
    padding: 10px 8px;
}

#DF-main-types .MuiPopover-paper {
    position: relative !important;
}

/* .MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--pink-primary-color) !important;
} */

.mfa-btn {
    font-size: 15px !important;
    padding: 6px 20px !important;
}

.verify-mfa-btn.mfa-btn {
    background: var(--black1);
    color: var(--white1);
}

.verify-mfa-btn.mfa-btn:hover {
    background: var(--pink-primary-color);
    color: var(--white1);
}

#action-items-table {
    overflow-x: auto;
    min-width: 100%;
    /* width: max-content; */
}

.sidebar-items .MuiSvgIcon-root:hover {
    color: var(--pink-primary-color);
}

.connectivity-status-tabs .MuiTabs-scroller.MuiTabs-fixed {
    max-width: 100%;
    overflow: auto !important;
}

.MuiDropzoneArea-root {
    border: 1px dashed #e0e0e0 !important;
    min-height: inherit !important;
}

.MuiDropzoneArea-text-updated {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    margin-left: 10px !important;
    display: inline-block !important;
    text-align: start !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: var(--black-primary-color);
    font-family: 'Manrope' !important;
}

.MuiDropzoneArea-icon {
    color: #008EB9 !important;
    width: 30px !important;
    ;
    height: 30px !important;
    ;
    display: inline-block !important;
    margin: 0px 10px;
}

.containerClassDrag .MuiDropzoneArea-icon {
    width: 26px !important;
    height: 26px !important;
    display: inline-block !important;
    margin: 4px 10px;
    margin-right: 30px;
    color: #F7008C;
}

.exchangeAccordion:before {
    background-color: transparent !important
}

.exchangeAccordion {
    border-radius: 4px !important;
}

.title-statistics {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.subtitle-statistics {
    font-size: 15px;
    font-weight: 600;
}

.exchangeAccordionEdit:before {
    background-color: transparent !important
}

.exchangeAccordionEdit {
    border-radius: 0px !important;
    padding: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.exchangeAccordionEdit .MuiAccordionSummary-expandIconWrapper {
    position: absolute !important;
    margin-top: 35px !important;
}

.disable-btn {
    display: inline-block;
    min-width: 64px;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: #E6E6E6;
    line-height: 1.75;
    color: #79828b;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    vertical-align: middle;
    border-radius: 50px;
    padding: 12px 30px;
    font-size: 15px;
    font-weight: 800;
    box-shadow: unset;
}

.oe-edit-class .MuiTab-root {
    max-width: -webkit-fill-available !important
}

.attachment-badge {
    display: inline-flex;
    background-color: #E5E5E5;
    margin: 5px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 25PX;
}

.escalated .MuiButtonBase-root.Mui-disabled {
    background: var(--red) !important;
    background-color: var(--red) !important;
    color: var(--white-primary-color) !important;
    /* border-radius: 50px !important;
    padding: 12px 30px !important;
    font-weight: 800 !important;
    font-size: 15px !important;
    */
}

.MuiChip-root {
    min-height: 32px;
    padding: 7px;
}

.forms-attachments-section .MuiAccordionSummary-content {
    display: block;
}

.MuiChip-label {
    white-space: normal !important;
}

.truncedChip {
    color: #292929;
    background-color: #FAFBFC;
    /* min-height: 32px; */
    padding: 2px 10px;
    border-radius: 16px;
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border: 1px solid #E0E0E0;
}

.qr-dialog .MuiPaper-root {
    min-width: 50%;
    min-height: 50%;
    padding: 30px;
}

.qr-dialog .MuiPaper-root .dialog-title {
    box-shadow: 0px 2px 1px rgb(0 0 0 / 10%);
    padding-bottom: 10px;
}

.qr-dialog .MuiPaper-root .dialog-body {
    padding: 30px 0;
    text-align: left;
}

.qr-dialog .MuiPaper-root .dialog-footer {
    padding-top: 10px;
    text-align: end;
}

.dateCancelation {
    font-family: 'Manrope';
    font-size: 15px;
    background-color: #ffffff;
}

.addLineOver {
    -webkit-text-decoration-line: line-through;
    /* Safari */
    text-decoration-line: line-through;
}

.text-alert-yellow {
    font-family: 'Manrope';
    font-size: 14px;
    color: var(--yellow-secondary-color);
}

.alert-future-date {
    font-family: 'Manrope';
    font-size: 13px;
    color: var(--grey2);
}

.warmingOERequest {
    color: var(--yellow-secondary-color);
    font-family: 'Manrope';
    /* float: right; */
}

.sendOEButtonDashboard {
    /* color: var(--black2); */
    border: 0;
    background: none;
}

.outer-card-settings {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 30px !important;
    border-radius: 10px;
}

.notification-card {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    align-items: center;
    padding: 15px;
}

.warningLink {
    text-decoration: underline;
    cursor: pointer;
    padding-top: 8px;
    display: block;
    color: #007bff;
}

.plans-list-div {
    display: block;
    max-height: 350px;
    overflow-x: auto;
    font-size: 12px;
}

.comment-attachment-div {
    padding: 10px 15px;
    border: 1px solid rgba(204, 204, 204, 0.4);
    background-color: var(--grey5);
}

.homepage-main-item-container {
    color: white;
    padding: 10px 10px;
    text-align: center;
    min-height: 160px;
    display: grid;
    align-content: center;
    width: 90%;
    cursor: pointer;
    border-radius: 10px;
    font-size: larger;
    font-weight: 500;
}

.sub-header-table.table-cell {
    padding: 0 !important;
}

.sub-header-table .table-cell {
    padding: 0 !important;
    border-bottom: 0;
}

@font-face {
    font-family: 'signature-font';
    src: url('../public/signature-font.ttf');
    font-style: normal;
    font-weight: normal;
}

.signatureFont {
    font-family: 'signature-font', sans-serif;
}

.lineHeight-1-6em {
    line-height: 1.6em
}

.btn-like-link {
    border: none;
    background: none;
}

.btn-like-link.table-cell-link {
    color: var(--dt-cell-gray);
}

.btn-like-link.table-cell-link:hover {
    text-decoration: underline;
    color: #0056b3;
}

/* =======================NEW TABS */
.new-tabs {
    height: 36px;
}

.new-tabs .MuiTab-root {
    border: 1px solid var(--grey6);
    min-width: 77px !important;
    height: 36px !important;
    min-height: 36px !important;
    font-size: 14px !important;
    font-weight: 400;
    color: var(--gray8);
    background: transparent;
}


.new-tabs .MuiTab-root:hover {
    border-color: var(--blue-main-color);
    background-color: var(--newTabsHover-bg) !important;
    color: var(--dark-blue-secondary-color);
    font-weight: 500;
}

.new-tabs .MuiTab-root.Mui-selected,
.new-tabs .MuiTab-root.selected {
    border-color: var(--blue4);
    background-color: var(--blue5) !important;
    color: var(--blue6);
    font-weight: 500;
}


.new-tabs .MuiTabs-indicator {
    display: none !important;
}

.new-tabs .MuiTabs-flexContainer {
    display: flex;
}

.new-tabs .MuiTabs-flexContainer .flex-grow-1 {
    max-width: none !important;
}


.w-100-minus-81 {
    width: calc(100% - 81px);
}

.more-btn-dd {
    width: 81px;
}

.more-btn-dd .dropdown-menu {
    inset: 0px 0 auto auto !important;
}

.more-tab-btn {
    background: none !important;
    border-radius: 0 !important;

    border: 1px solid var(--grey6) !important;
    min-width: 77px !important;
    height: 36px !important;

    min-height: 36px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: var(--gray8) !important;
}

.more-tab-btn.active {
    border-color: var(--blue4) !important;
    background-color: var(--blue5) !important;
    color: var(--blue6) !important;
    font-weight: 500 !important;
}

.more-tab-menu-item {
    min-width: 183px;
    height: 36px;
    padding-right: 7px;
    padding-left: 7px;
    font-size: 11px;
    font-weight: 400;
    color: var(---dt-cell-gray);
    border-bottom: 1px solid var(--gray-border);
    display: flex;
    align-items: center;
}

.more-tab-menu-item:last-child {
    border-bottom: 0 !important;
}

.more-tab-menu-item:hover {
    background-color: transparent;
}

.more-tab-menu-item.active {
    color: var(--blue6);
    font-weight: 500;
    background: var(--grey-table-cell-color);
}

.more-tab-btn::after {
    display: none;
}

.internalCarrier-radio-group {
    height: 300px;
    width: 300px;
    flex-wrap: nowrap !important;
    overflow: scroll;
    padding-left: 13px;
}

.ml-0-imp {
    margin-left: 0 !important;
}

.border-bottom {
    border-bottom: 1px solid var(--grey6) !important;
}

.progress-container {
    border: 1px solid #e0e0e0;
    border-radius: 9px;
    padding: 10px;
    width: 100%;
    min-width: 200px;
    min-height: 68px;
    max-height: 88px;
    margin-bottom: 10px;
}

.progress-container span {
    font-weight: 500;
    font-size: 14px;
    color: #1f1f1f;
}

.progress-container .linearprogress-stats {
    margin-top: 18px;
    border-radius: 999px;
    height: 6px;
}

.progress-container .linearprogress-stats.blue .MuiLinearProgress-barColorPrimary {
    background-color: #2f8bfa;
}

.progress-container .linearprogress-stats.blue.MuiLinearProgress-colorPrimary {
    background-color: #ecf4ff;
}

.progress-container .linearprogress-stats.green .MuiLinearProgress-barColorPrimary {
    background-color: #00978e;
}

.progress-container .linearprogress-stats.green.MuiLinearProgress-colorPrimary {
    background-color: #e8f6f0;
}

.progress-container .linearprogress-stats.red .MuiLinearProgress-barColorPrimary {
    background-color: #da5740;
}

.progress-container .linearprogress-stats.red.MuiLinearProgress-colorPrimary {
    background-color: #ffe4c3;
}

.stats-counts {
    font-weight: 400;
    font-size: 12px;
    color: #4e4e4e;
    margin-top: 10px;
}

.counter-external-div .truncedChip {
    white-space: nowrap;
}

.cursor-pointer {
    cursor: pointer !important;
}

.justify-content {
    justify-content: center !important;
}