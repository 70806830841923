a {
    color: inherit;
}


/* #home-dashboard a:hover {
    text-decoration: none;
} */

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.mt--1 {
    margin-top: -1px !important;
}

.mt--2 {
    margin-top: -2px !important;
}

.mt--4 {
    margin-top: -4px !important;
}

.no-data-alert {
    text-align: center;
    color: #828282;
    margin: 20px auto 20px auto;
}

h6.form-title {
    font-weight: 500;
    font-size: 12px;
    color: var(--black-primary-color);
}

#sidebar-drawer-div .MuiTypography-root {
    font-size: 12px !important;
}

.title-typography.MuiTypography-root {
    border-bottom: 1px solid var(--grey1-secondary-color) !important;
    font-weight: 500;
    font-size: 16px !important;
    color: var(--black-primary-color);
    width: 100%;
    padding: 10px 0;
}

.MuiAccordionSummary-content .MuiTypography-root {
    margin-top: 5px;
    margin-left: 5px;
}

.accordion-checkboxes .MuiTypography-root {
    font-size: 12px !important;
}

.missing-data-warning {
    color: var(--red) !important;
    font-weight: 600;
}

.components-div .MuiTypography-root {
    font-size: inherit;
}

/* .MuiAccordionSummary-content .Mui-checked {
    color: var(--pink-primary-color);
} */

/* .MuiCheckbox-root.Mui-checked {
    color: var(--pink-primary-color);
} */

.table-header .MuiCheckbox-root.Mui-checked {
    color: var(--white1);
}

.table-header .MuiSvgIcon-root {
    color: var(--white1);
}

.toggle-on-text {
    color: var(--green) !important;
    border-radius: 50px !important;
    padding: 7px 25px !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    border: 3px solid var(--green);
}

.toggle-off-text {
    color: var(--red) !important;
    border-radius: 50px !important;
    padding: 7px 25px !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    border: 3px solid var(--red);
}

.MuiInput-underline:after {
    border-bottom: unset !important;
}

.MuiAutocomplete-input {
    padding: 11px 6px !important;
}

#last-sent-file-modal span {
    font-size: 14px !important;
    font-weight: 400 !important;
}

/* #last-sent-file-modal .MuiInputBase-root {
    font-size: 14px !important;
} */

#df-matching-plans-modal span {
    font-size: 14px !important;
    font-weight: 400 !important;
}

#df-matching-plans-modal .MuiInputBase-root {
    font-size: 14px !important;
}

.radio-list-options .MuiTypography-body1 {
    font-size: inherit;
}

.dz-message {
    font-size: 14px;
}

.MuiFormHelperText-root {
    font-size: 10px !important;
}

.preformatted-text {
    white-space: pre-line;  /* Ensures that \n is rendered as a line break */
  }