/* Generic */

.MuiTab-root {
    max-width: fit-content !important;
}

.MuiDialog-paperWidthSm {
    max-width: unset !important;
}

.MuiInput-underline:before {
    border-bottom: unset !important;
}

.MuiListItem-root.MuiMenuItem-root {
    min-height: 30px !important;
    font-size: 12px !important;
}

/* ====================================== */

.MuiRadio-root .MuiIconButton-label {
    position: relative !important;
    width: 16px !important;
    height: 16px !important;
    /* background: none !important; */
    border: none !important;
    padding: 0 !important;
}

.MuiRadio-root .MuiSvgIcon-root {
    display: none !important;
}

.MuiRadio-root .MuiIconButton-label {
    content: "";
    display: block !important;
    background: url('../Icons/radiobuttonUnchecked.svg') no-repeat center center;
    background-size: contain !important;
    width: 16px;
    height: 16px;
}

.MuiRadio-root.Mui-checked .MuiIconButton-label {
    background: url('../Icons/radiobuttonChecked.svg') no-repeat center center;
}

.MuiRadio-root.Mui-disabled .MuiIconButton-label {
    background: url('../Icons/radiobuttonDisabled.svg') no-repeat center center;
}

.MuiRadio-root.Mui-checked.Mui-disabled .MuiIconButton-label {
    background: url('../Icons/radiobuttonDisabledChecked.svg') no-repeat center center;
}

/* ================================ */

/* ==================================================== */
.MuiCheckbox-root .MuiIconButton-label {
    position: relative !important;
    width: 16px !important;
    height: 16px !important;
    /* background: none !important; */
    border: none !important;
    padding: 0 !important;
}

/* Hide the checkbox outline and default icons */
.MuiCheckbox-root .MuiSvgIcon-root {
    display: none !important;
}

.MuiCheckbox-root .MuiIconButton-label {
    content: "";
    display: block !important;
    background: url('../Icons/Checkbox.svg') no-repeat center center;
    background-size: contain !important;
    width: 16px;
    height: 16px;
}

.MuiCheckbox-root .MuiIconButton-label:hover {
    background: url('../Icons/checkboxHover.svg') no-repeat center center;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label {
    background: url('../Icons/checkedCheckbox.svg') no-repeat center center;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label:hover {
    background: url('../Icons/checkboxCheckedHover.svg') no-repeat center center;
}

.MuiCheckbox-root.Mui-disabled .MuiIconButton-label {
    background: url('../Icons/disabledCheckbox.svg') no-repeat center center;
}

.MuiCheckbox-root.Mui-checked.Mui-disabled .MuiIconButton-label {
    background: url('../Icons/checkedDisabledcheckbox.svg') no-repeat center center;
}

/* ==================================================== */
/* Home Admin Dashboard */


/* #transmission-dashboard-table .MuiTab-root {
    color: var(--grey3);
    border: 1px solid rgba(184, 197, 208, 0.2);
    border-radius: 50px;
    font-size: 16px;
    font-weight: 400;
}

#transmission-dashboard-table .MuiTab-root.Mui-selected {
    background: var(--pink-primary-color);
    color: var(--white1);
}

#transmission-dashboard-table .MuiTabs-indicator {
    background-color: unset !important;
} */


.MuiTabs-indicator {
    display: none !important;
}

.MuiTabs-flexContainer .Mui-selected {
    position: relative;
}

.MuiTabs-flexContainer .Mui-selected::after {
    content: '';
    width: 100%;
    height: 3px;
    background: var(--pink-primary-color);
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.new-tabs .MuiTabs-flexContainer .Mui-selected::after,
.MuiTabs-vertical .MuiTabs-flexContainer .Mui-selected::after {
    display: none !important;
}

.MuiTabs-root {
    min-height: unset !important;
}

/* Header Component */

#header .MuiBadge-badge {
    height: 1.05rem !important;
    min-width: 1.05rem !important;
    background-color: var(--pink-primary-color) !important;
}


/* Sidebar Component */

#sidebar .MuiDrawer-paper {
    top: 100px !important;
    height: fit-content !important;
    background: var(--white1);
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 0px 30px 30px 0px;
    position: unset !important;
}

#sidebar .MuiListItem-root {
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
}


/* Breadcrumbs Component */

#breadcrumb-steps .MuiTypography-root {
    font-size: 14px !important;
}


/* Create Group Request Component */

.carriers-contact-acc.MuiPaper-elevation1 {
    box-shadow: unset !important;
}

.carriers-contact-acc .MuiAccordionDetails-root {
    padding: 0 !important;
}

#select-carrier-plans .MuiAppBar-colorDefault {
    color: unset !important;
    background-color: unset !important;
}

#select-carrier-plans .MuiPaper-elevation4 {
    box-shadow: unset !important;
}

#create-grp-request .tab-style {
    width: 100%;
}

#select-carrier-plans .tab-style .MuiTabs-indicator {
    background-color: unset !important;
}

#select-carrier-plans .MuiBox-root-24 {
    padding: unset !important;
}

#user-assignment .MuiAppBar-colorDefault {
    color: unset !important;
    background-color: unset !important;
}

#user-assignment .MuiPaper-elevation4 {
    box-shadow: unset !important;
}

#user-assignment .tab-style {
    width: 100%;
}

#user-assignment .tab-style .MuiTabs-indicator {
    background-color: unset !important;
}

#user-assignment .MuiBox-root-24 {
    padding: unset !important;
}


/* Carrier Contacts Table Component */

#carrier-contacts .MuiAppBar-colorDefault {
    color: unset !important;
    background-color: unset !important;
}

#carrier-contacts .MuiPaper-elevation4 {
    box-shadow: unset !important;
}

#carrier-contacts .tab-style .MuiTabs-indicator {
    background-color: unset !important;
}

#carrier-contacts .MuiBox-root {
    padding: 0 !important;
}


/* Select Carriers And Plans Component */

/* #select-carriers-plans .MuiAccordionSummary-root {
    padding: 0px 25px !important;
} */


/* #select-carriers-plans .MuiAccordionSummary-content .MuiTypography-root {
    font-size: 14px !important;
    font-weight: 600;
    color: var(--black2);
    letter-spacing: 0.3px;
    margin-top: 5px;
    margin-left: 5px;
} */


/* #select-carriers-plans  */

#select-carriers-plans .MuiAccordionDetails-root {
    padding: 0px 61px 16px 61px !important;
    display: block !important;
}

#select-carriers-plans .MuiAccordionDetails-root .MuiTypography-root {
    font-size: 14px !important;
    font-weight: 500;
    color: var(--black3);
    letter-spacing: 0.3px;
    margin-top: 5px;
    margin-left: 5px;
}

#select-carriers-plans .MuiAccordionDetails-root .Mui-checked {
    color: var(--pink-primary-color);
}


/* Select Subgroups Component */

#select-subgroups-plans .MuiAccordionSummary-root {
    padding: 0px 25px !important;
}

/* #select-subgroups-plans .MuiAccordionSummary-content .Mui-checked {
    color: var(--pink-primary-color);
} */

#select-subgroups-plans .MuiAccordionDetails-root {
    padding: 0px 61px 16px 61px !important;
    display: block !important;
}

#select-subgroups-plans .MuiAccordionDetails-root .MuiTypography-root {
    font-size: 14px !important;
    font-weight: 500;
    color: var(--black3);
    letter-spacing: 0.3px;
    margin-top: 5px;
    margin-left: 5px;
}

#select-subgroups-plans .MuiAccordionDetails-root .Mui-checked {
    color: var(--pink-primary-color);
}


/* View Group Request Component */
.connectivity-req-acc::before {
    display: none !important;
}

.connectivity-req-acc.MuiPaper-elevation1 {
    box-shadow: unset !important;
}

.connectivity-req-acc .MuiAccordionDetails-root {
    padding: 0 !important;
}

#view-group-request .MuiButton-contained.Mui-disabled {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    background-color: var(--grey7disabled) !important;
    color: var(--black3) !important;
}


/* View Connectivity Request Component */

.filter-form .MuiOutlinedInput-input {
    padding: 8px 14px !important;
}

#carrier-contacts .MuiTextField-root {
    width: 100%;
}

#view-connectivity-requests .MuiAutocomplete-inputRoot {
    padding-right: 50px;
    max-height: 40px;
    background: #FFFFFF;
    border-color: var(--pink-primary-color) !important;
    border-radius: 2px;
    color: rgba(35, 35, 39, 0.836589);
    margin-top: 7px !important;
    max-width: 265px;
    font-size: 12px;
}

#view-connectivity-requests .MuiAutocomplete-input {
    padding-top: 2px !important;
}


/* Cancel Connectivity component */

#cancel-connectivity .MuiButton-contained.Mui-disabled {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    background-color: var(--grey7disabled) !important;
    color: var(--black3) !important;
}

.alert-icon.MuiSvgIcon-fontSizeLarge {
    font-size: 60px;
}


/* Feeds Create */


/* #create-feed .MuiTypography-root,
#feed-config .MuiTypography-root {
    font-size: 14px;
} */


/* Feeds Analyst View List */

#view-feeds .MuiTabs-scroller {
    max-width: 100% !important;
    overflow-x: auto !important;
}


/* Feeds Configuration */

#scheduler-config .Mui-checked {
    color: var(--pink-primary-color);
}

#scheduler-config .MuiSvgIcon-root {
    font-size: 25px;
}

#scheduler-config .MuiTypography-root {
    font-size: 14px;
}


/* Feed Scheduler Configuration */

.Mui-checked+.MuiSwitch-track {
    background-color: var(--blue-main-color) !important;
}

#scheduler-frequency .MuiToggleButtonGroup-vertical {
    min-width: 100%;
}

#scheduler-frequency .MuiToggleButtonGroup-groupedVertical.Mui-selected {
    background-color: var(--blue-main-color);
}

#scheduler-frequency .MuiToggleButtonGroup-groupedVertical.Mui-selected .MuiToggleButton-label {
    color: var(--white1);
}

#create-feed .MuiButton-contained.Mui-disabled {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    background-color: var(--grey7disabled) !important;
    color: var(--black3) !important;
}

#scheduler-frequency .MuiChip-outlined {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: unset;
}

#scheduler-frequency .MuiChip-root {
    border-radius: 999px;
    padding: 3px 9px;
    height: 27px;
    background: #f3f3f3;
}

#scheduler-frequency .MuiChip-label {
    overflow: auto !important;
    text-overflow: unset !important;
    font-weight: 700;
    font-size: 12px;
    color: #5e5e5e;
}

#scheduler-frequency .MuiInput-root {
    letter-spacing: 3px;
}

#monthly-scheduler .MuiOutlinedInput-input {
    padding: 8px 14px;
}


/* Connectivity Request Config */

#connect-req-config .MuiButton-contained.Mui-disabled {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    background-color: var(--grey7disabled) !important;
    color: var(--black3) !important;
}


/* Maps View Component */

#maps-list .MuiAutocomplete-inputRoot {
    padding-right: 50px;
    height: 32px;
    background: #FFFFFF;
    border-color: var(--pink-primary-color) !important;
    border-radius: 2px;
    color: rgba(35, 35, 39, 0.836589);
    margin-top: 7px !important;
    /* max-width: 265px; */
    font-size: 12px !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 4px;
}

/* #maps-list .MuiAutocomplete-input {
    padding-top: 2px !important;
} */


/* Structures View Component */

#structures-list .MuiAutocomplete-inputRoot {
    padding-right: 50px;
    height: 32px;
    background: #FFFFFF;
    border-color: var(--pink-primary-color) !important;
    border-radius: 2px;
    color: rgba(35, 35, 39, 0.836589);
    margin-top: 7px !important;
    /* max-width: 265px; */
    font-size: 12px !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 4px;

}

#structures-list .MuiAutocomplete-input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    font-size: 12px !important;
}


.MuiAutocomplete-option {
    font-size: 12px;
}


/* Carriers Component */

#customized-dialog .MuiDialogTitle-root {
    min-width: 200px;
    background-color: #FAFAFA;
    border-bottom: 1px solid #e2e2e2;
}

#customized-dialog .MuiDialogTitle-root #df-matching-plans-modal {
    min-width: 900px;
}

.public-key-controls.MuiButton-root.Mui-disabled {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    background-color: var(--grey7disabled) !important;
    color: var(--black3) !important;
}

#customized-dialog .MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 2 !important;
    justify-content: center;
}

/*Scheduler feed Component */

#create-scheduler .MuiAccordion-root:before {

    background-color: unset !important;
}

.MuiTabs-flexContainer {
    overflow-x: auto;
}

.overflow-x-auto-tabs .MuiTabs-indicator {
    display: none;
}

.overflow-x-auto-tabs .Mui-selected {
    border-bottom: 3px solid var(--pink-primary-color);
}

.search-dropdown .MuiAutocomplete-endAdornment {
    top: 2px;
    right: 0;
    position: absolute;
    font-size: 12px;
}

.search-dropdown .MuiNativeSelect-icon {
    top: 4px;

}

.select-carrier-accordion {
    background: transparent !important;
    border: 0 !important;
}

/* Checked state */
.MuiSwitch-colorSecondary.Mui-checked {
    color: var(--blue-main-color) !important;
    /* Switch knob color when checked */
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: var(--blue-main-color) !important;
    /* Track color when checked */
}