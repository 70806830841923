.table-header {
    background: var(--black-table-header-color);
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #fff;
}

.table-subheader {
    background: rgba(79, 79, 79, .5);
    font-weight: 700;
    font-size: 14px;
    color: var(--white-primary-color);
    text-align: center;
    margin-top: -2px;
}

.table-row {
    text-align: center;
    font-weight: 500;
    color: var(--black2);
}

.table-row-with-border-bottom {
    text-align: center;
    font-weight: 500;
    color: var(--black2);
    border-bottom: 0.5px solid #e0e0e0;
}



.table-cell {
    align-items: center;
    border-bottom: 1px solid #ccc6;
    border-right: 1px solid #ccc6;
    display: grid;
    padding: 10px !important;
}

.table-row:not(.with-right-border) .table-cell:last-child {
    border-right: none !important;
}

/* .table-cell.first-cell {
    border-left: 0.5px solid #e0e0e0;
} */

.table-cell.last-cell {
    border-right: 0.5px solid #e0e0e0;
}

.tab-style .MuiTab-textColorInherit {
    background: var(--pink-primary-color);
    border-radius: 0px 0px 4px 4px;
    border: 2px solid var(--white1);
    color: var(--white1);
    border-bottom: unset !important;
}

.tab-style .MuiTab-textColorInherit.Mui-selected {
    border-top: 5px solid var(--pink-primary-color);
    background: unset !important;
    color: var(--pink-primary-color);
    border-bottom: unset !important;
}

.tab-style .MuiTabs-indicator {
    background-color: unset !important;
}

.MuiAppBar-colorDefault {
    color: unset !important;
    background-color: unset !important;
}


/* connectivity-group-requests-table table cell Font-size */
#connectivity-group-requests-table .table-cell {
    font-size: 13px !important;
}

#connectivity-group-requests-table .table-header .table-cell {
    padding: 5px !important;
}

#connectivity-group-requests-table .table-row .table-cell {
    overflow: hidden;
}

#connectivity-group-requests-table .table-row-fs-12 .table-cell {
    font-size: 12px !important;
}

.self-flag {
    width: 3px;
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: -3px;
    background: var(--dark-pink);
}

.breakword {
    overflow-wrap: break-word;
    word-break: break-all;
}

/* General Table Styling */
.rdt_Table {
    border: 1px solid var(--gray-border);
    border-top: none;
    max-width: 100%;
    display: block;
    position: relative;
    border-collapse: collapse;
    /* Ensures the borders between cells collapse into a single border */

}

.rdt_TableHead {
    border-top: 1px solid var(--gray-border);

    position: sticky;
    top: 0;
    left: 0;
    z-index: 1
}

.rdt_TableHeadRow .rdt_TableCol {
    font-weight: 500;
    font-size: 11px;
    line-height: 155%;
    text-transform: capitalize;
    color: var(--dt-header-black) !important;
    border-right: 1px solid var(--gray-border);
}

.rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: none !important;
}

.rdt_TableCol_Sortable {
    opacity: 1 !important;
}

.rdt-even-row {
    background: #f5f5f5 !important;
}

.rdt_TableCell {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--dt-cell-gray);
    overflow: visible !important;
    text-overflow: initial !important;
    white-space: normal !important;
    max-width: 100%;
    /* Ensure cells do not exceed container width */
    min-width: 100px;
}

/* .rdt_TableCol div  >> header don't truckate words */

.rdt_TableCol div[role="columnheader"] {
    overflow: visible !important;
    text-overflow: initial !important;
    white-space: normal !important;
}

/* .rdt_TableCol {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdt_TableCol div {
    text-align: center;
} */

.rdt_TableCell,
.rdt_TableCol {
    padding: 0 8px !important;
}


/* Container Styling */
.table-container {
    max-width: 100%;
    /* Prevents container from exceeding its parent width */
    overflow-x: auto;
    /* Allows horizontal scrolling if content overflows */
    white-space: nowrap;
    /* Prevents content from wrapping */
    box-sizing: border-box;
    /* Includes padding and border in the element's total width */
}

.rdt_TableBody {
    display: block;
    /* Ensures table body respects container width */
}

.rdt_TableRow {
    display: table;
    /* Ensures rows are displayed as table rows */
    width: 100%;
    /* Prevents rows from exceeding container width */
}

.no-data-alert {
    text-align: center;
    padding: 20px;
}

.max-w-100 {
    max-width: 100% !important;
}

.table-row-bordered-l {
    border-left: 1px solid var(--grey6);
}

.table-row-bordered-l-r {
    border-left: 1px solid var(--grey6);
    border-right: 1px solid var(--grey6);
}