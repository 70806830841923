.textfield-div {
    background-color: #FAFAFA;
    border: 1px solid var(--grey6) !important;
    border-radius: 3px;
    padding: 6px 20px;
    color: #292929;
    display: -webkit-inline-box;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    min-height: 32px;
    overflow-y: auto;
    word-break: break-all;
    height: max-content;
    max-height: 200px;
    width: 100%;
}

.word-break-all {
    word-break: break-all;
}

.text-input {
    border-radius: 5px;
    /* min-height: 53px; */
    height: 32px;
    padding: 0px 7px;
    border: 1px solid var(--grey6);
    display: flex;
    align-items: center;
    font-size: 11px;
}

.multiple-select-dropdown {
    /* border: 1px solid rgba(0, 0, 0, 0.23) !important; */
    border: 1px solid var(--grey6);
    padding: 10px 8px !important;
    border-radius: 3px;
    height: 32px;
    display: flex !important;
    align-items: center;
}

.intl-tel-input input {
    border: unset;
}

.intl-tel-input input:focus {
    outline: unset;
}

.text-dropdown {
    height: 32px;
    align-items: center;
}

.search-input {
    padding: 0.5em;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid var(--grey6);
    border-radius: 5px;
    margin-top: 7px;
    font-size: 11px;
}

.date-div {
    display: inline-flex;
    padding: 2px 0.5em;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid var(--grey6);
    border-radius: 5px;
    font-size: 12px;
    margin-top: 7px;
}

.text-input:has(.Mui-error),
.date-div:not(.remove-dateField-validation-style):has(.Mui-error) {
    border-color: var(--red-border-error) !important;
}

.remove-dateField-validation-style .MuiFormHelperText-root.Mui-error {
    display: none;
}

.text-input .MuiFormControl-root,
.date-div .MuiFormControl-root {
    height: 100%;
}

.search-dropdown {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid var(--grey6) !important;
    border-radius: 5px;
    margin-top: 7px !important;
    /* max-width: 230px; */
    padding-left: 5px;
    font-size: 11px;
}

.search-dropdown-carrier {
    height: 32px;
    background: #FFFFFF;
    /* border: 1px solid rgba(79, 79, 79, 0.5) !important; */
    border: 1px solid var(--grey6);
    border-radius: 2px;
    margin-top: 12px !important;
    padding-right: 5px;
    /* padding-top: 8px; */
    border-radius: 10px;
}

.search-dropdown-carrier input {
    padding-left: 60px !important;
}

.absolute-icon {
    position: absolute;
    left: 16px;
    top: 16px;
    color: var(--pink-primary-color);
}

.container-div {
    border: 1px solid var(--grey6);
}

.container-internal-div {
    border: 1px solid var(--grey6);
    padding: 24px;
}

.container-internal-file-div {
    padding: 24px;
    /* background: var(--grey7disabled); */
    border: 1px solid var(--grey6) !important;
    border-radius: 3px;
    display: grid;
    /* max-width: 15%;
    min-width: 15%; */
    justify-content: center;
    text-align: center;
    justify-items: center;
}

.counter-div {
    /* background: var(--pink-primary-color); */
    border-radius: 50px;
    padding: 8px 15px;
    color: #636363;
    font-weight: 400;
    font-size: 12px;
}

.counter-div-carriers {
    background: #F03A44;
    border-radius: 20px;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    width: 23px;
    height: 20px;
    color: white;
    justify-content: center;
    display: flex;
}

.counter-external-div {
    border-radius: 3px;
    min-height: 53px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
}

.MuiOutlinedInput-input {
    padding: 0px 6px !important;
    font-size: 12px !important;
}

.pmf-summary-section-div {
    margin-bottom: 15px;
    padding: 20px 50px;
    box-shadow: 0 0px 4px rgb(90 90 90 / 42%);
    background-color: var(--grey5);
}

.userInfo .user {
    border-radius: 50px;
    color: var(--white1);
    padding: 10px;
}

.search-container {
    margin-bottom: 36px;
}

.MuiFormControlLabel-root span,
.search-container span {
    font-size: 12px;
}

#create-feed .MuiAccordion-root,
#create-grp-request .MuiAccordion-root,
#view-group-request .MuiAccordion-root,
#connect-req-config .MuiAccordion-root,
.newUi-collapse .MuiAccordion-root {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: none;
}

#create-feed .MuiAccordionSummary-root,
#create-grp-request .MuiAccordionSummary-root,
#view-group-request .MuiAccordionSummary-root,
#connect-req-config .MuiAccordionSummary-root,
.newUi-collapse .MuiAccordionSummary-root {
    background: #fafbfc;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0px 12px !important;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    color: #454545;
}

.newUi-collapse .MuiCollapse-container {
    padding: 24px !important;
}

.remove-border-and-bg {
    background: transparent !important;
    border: none !important;
}

#cr-carrier-contacts .MuiAccordionSummary-content {
    display: contents;
}

.css-15v22id-MuiAccordionDetails-root,
.add-oe-request-from-gr .MuiCollapse-root {
    padding: 24px !important;
}

.no-padding-accordion-details.css-15v22id-MuiAccordionDetails-root {
    padding: 1px !important;
}

#connect-req-config .MuiAutocomplete-inputRoot {
    overflow-x: auto;
    overflow-y: auto;
}

.autocomplete-tabs .MuiChip-root {
    min-height: unset;
    height: unset;
}

.autocomplete-tabs .MuiChip-root .MuiChip-label {
    white-space: nowrap !important;
}

.checkbox-list {
    display: block;
}

.checkbox-list label {
    margin-bottom: 0;
}

.checkbox-list .MuiTypography-body1 {
    font-size: 12px;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}

#connectivity-request-files-section .MuiTabs-root,
#connectivity-request-forms-section .MuiTabs-root {
    min-height: unset;
}

#connectivity-request-files-section .MuiAppBar-root,
#connectivity-request-forms-section .MuiAppBar-root {
    border-bottom: 1px solid #e0e0e0;
    /* margin-bottom: 36px; */
}

#connectivity-request-files-section .label-text,
#connectivity-request-forms-section .label-text {
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
}

#connectivity-request-files-section .MuiDropzoneArea-textContainer {
    text-align: unset;
}

#connectivity-request-files-section .MuiDropzoneArea-icon {
    float: right;
}

#connectivity-request-forms-section .MuiDropzoneArea-root {
    border: 1px dashed #e0e0e0;
    border-radius: 4px;
    width: 100%;
    height: 167px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#connectivity-request-forms-section .MuiDropzoneArea-text {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: 'Manrope' !important;
}

.cr-section {
    min-width: 1200px;
}

.connectivity-request-parent {
    overflow-x: auto;
    width: 100%;
}

.p-0-imp {
    padding: 0 !important;
}

.remove-textfield-div-style {
    background-color: unset;
    border: none !important;
    justify-content: center;
}