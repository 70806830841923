.filter-full-div .MuiDialog-paper {
    min-width: 800px !important;
    max-width: 1000px !important;
    min-height: 600px !important;
    max-height: 600px !important;
    /* background-color: #F7F9FC; */
}

.filter-title-div {
    background-color: #FAFAFA;
    color: #292929;
    border-bottom: 1px solid #e2e2e2;
}

.filter-body-div {
    /* border: 2px solid rgba(204, 204, 204, 0.4); */
    margin: 30px 0px;
    border-radius: 5px;
    background-color: var(--white-primary-color);
}

.filter-body-div .MuiTabs-root.MuiTabs-vertical {
    border-right: 2px solid rgba(204, 204, 204, 0.4);
}

.filter-body-div .MuiTabs-fixed {
    overflow: auto !important;
}

.filter-tab-btn.MuiTab-root {
    /* border: 2px solid #F7F9FC; */
    background-color: var(--white-primary-color);
    color: var(--black10);
    text-align: left;
    text-transform: capitalize;
    font-family: 'Manrope';
    font-size: 12px;
    border-radius: 0px, 0px, 0px, 2px;
    min-width: 100%;
    width: 200px;
    font-weight: 400 !important ;
}

.filter-tab-btn.MuiTab-root.Mui-selected {
    background-color: var(--light-grey) !important;
    border-right: unset;
    color: var(--black-dark-color) !important;
    font-weight: 500 !important;
    border-left: 3px solid var(--pink-primary-color);
}

.filter-tab-btn .MuiTab-wrapper {
    align-items: unset !important;
}

.filter-body-main-content {
    flex-grow: 12;
    padding: 15px;
}

.filter-footer-div {
    margin: 10px;
}