@media (max-width: 1720px) {
    .table-cell.date {
        font-size: smaller;
    }
    #connectivity-requests .status-div {
        font-size: smaller;
    }
}

@media (min-width: 600px) {
    .MuiListItem-root.MuiMenuItem-root {
        min-height: 30px !important;
    }
}