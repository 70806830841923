.black-btn {
    background-color: var(--black-primary-color) !important;
    color: var(--white-primary-color) !important;
    border-radius: 50px !important;
    padding: 12px 30px !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    box-shadow: unset !important;
    border: none !important;
}

.black-outlined-btn {
    background-color: var(--white-primary-color) !important;
    color: var(--black-primary-color) !important;
    border: 3px solid var(--black-primary-color) !important;
    border-radius: 50px !important;
    padding: 9px 27px !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    box-shadow: unset !important;
}

.pink-btn {
    background-color: var(--pink-primary-color) !important;
    color: var(--white-primary-color) !important;
    border-radius: 50px !important;
    padding: 12px 30px !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    box-shadow: unset !important;
}

.pink-btn-withoutradius {
    background-color: var(--pink-primary-color) !important;
    color: var(--white-primary-color) !important;
    padding: 8px 0px !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    box-shadow: unset !important;
}

.pink-outlined-btn {
    background-color: var(--white-primary-color) !important;
    color: var(--pink-primary-color) !important;
    border: 3px solid var(--pink-primary-color) !important;
    border-radius: 50px !important;
    padding: 9px 27px !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    box-shadow: unset !important;
}

.pink-outlined-square-btn {
    background-color: var(--white-primary-color) !important;
    color: var(--pink-primary-color) !important;
    border: 2px solid var(--pink-primary-color) !important;
    border-radius: 10px !important;
    padding: 3px 10px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    box-shadow: unset !important;
    margin: 0 10px;
}

.grey-btn {
    background-color: var(--grey2-secondary-color) !important;
    color: var(--white-primary-color) !important;
    border-radius: 50px !important;
    padding: 12px 30px !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    box-shadow: unset !important;
}

.grey-outlined-btn {
    background-color: var(--white-primary-color) !important;
    color: var(--grey2-secondary-color) !important;
    border: 3px solid var(--grey2-secondary-color) !important;
    border-radius: 50px !important;
    padding: 9px 27px !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    box-shadow: unset !important;
}

.MuiButton-root.Mui-disabled {
    background-color: var(--grey7disabled) !important;
    color: var(--black3) !important;
    border: unset !important;
    /* padding: 12px 30px !important; */
}

.blue-outlined-btn.MuiButton-root.Mui-disabled {
    background-color: var(--white-primary-color) !important;
    color: #89898A !important;
    border: 1px solid var(--grey6) !important;
}

.icon-btn.MuiButton-root.Mui-disabled {
    background-color: unset !important;
}

.add-request.pink-btn {
    position: absolute !important;
    align-self: baseline;
    z-index: 10;
}

.add-record-btn {
    background: var(--blue-main-color);
    border-radius: 4px;
    padding: 0px 15px;
    font-weight: 700;
    font-size: 12px;
    color: var(--white-primary-color);
    cursor: pointer;
    height: 26px;
    align-items: center;
    display: flex;
}

.load-more-btn.pink-btn {
    margin: 20px auto !important;
}

.browse-button {
    background-color: #ececec !important;
    padding: 2px 15px !important;
    border: 1px solid var(--black4) !important;
    text-transform: none !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    color: var(--black5) !important;
}

#transmission-dashboard-table .table-cell {
    font-size: 12px;
}

.map-carrier-btn {
    background-color: var(--white-primary-color) !important;
    color: var(--pink-primary-color) !important;
    border: 3px solid var(--pink-primary-color) !important;
    border-radius: 50px !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    box-shadow: unset !important;
    padding: 8px 15px !important;
}


.map-carrier-btn.MuiButton-root.Mui-disabled {
    padding: 10px 18px !important;
}

.toggle-on-btn {
    background: var(--green) !important;
    /* border-radius: 50px !important; */
    /* padding: 7px 25px !important; */
    /* font-weight: 700 !important; */
    font-size: 14px !important;
    color: var(--white-primary-color) !important;
}

.toggle-off-btn {
    background: var(--red) !important;
    /* border-radius: 50px !important;
    padding: 7px 25px !important;
    font-weight: 700 !important; */
    font-size: 14px !important;
    color: var(--white-primary-color) !important;
}

/* plus btn @SelectCarriersAndPlans */
.plus-carrier-btn {
    background-color: var(--white-primary-color) !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.transparent-pink-btn {
    white-space: nowrap;
    background: transparent !important;
    color: var(--dark-pink) !important;
    border: 2px solid var(--dark-pink) !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    padding: 10px 20px !important;
    font-weight: 700 !important;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
}

.transparent-pink-btn:hover {
    background: #ff336614 !important;
    transform: translateY(-2px);
}

/* .MuiButton-root:hover {
    text-decoration: none;
    background-color: white !important;
    background: white !important;
} */

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.blue-btn {
    background-color: var(--blue-main-color) !important;
    color: var(--white-primary-color) !important;
    border-radius: 4px 0px 0px 0px;
    padding: 0px 12px 0px 12px;
    font-size: 14px !important;
    font-weight: 500 !important;
    box-shadow: unset !important;
    border: none !important;
    min-width: 100px;
    height: 35px;
}

.blue-outlined-btn {
    background-color: var(--white-primary-color) !important;
    color: var(--blue-main-color) !important;
    border: 1px solid var(--blue-main-color) !important;
    border-radius: 4px 0px 0px 0px;
    padding: 0px 12px 0px 12px;
    font-size: 14px !important;
    font-weight: 500 !important;
    box-shadow: unset !important;
    min-width: 100px;
    height: 35px;
}

.blue-outlined-btn:hover {
    background: #EDF5FC !important;
}

.add-record-btn:hover,
.blue-btn:hover {
    background: #006787 !important;
}

.transmission-actions-btn {
    font-size: 11px !important;
    text-align: left;
}