.web-main.background {
    background: #E5E5E5 !important;
}

:root {
    --pink-primary-color: #F7008C;
    --black-primary-color: #000000;
    --white-primary-color: #FFFFFF;
    --blue-secondary-color: #0F23B6;
    --yellow-secondary-color: #EBA400;
    --baby-blue-secondary-color: #00A6DA;
    --grey1-secondary-color: #B1B1B1;
    --grey2-secondary-color: #4F4F4F;
    --grey3-secondary-color: #4E4E4E;
    --blue-main-theme: #1E87F0;
    --black1: #000000;
    --black2: #42505C;
    --black3: #79828B;
    --black4: #707C97;
    --black5: #3A3B3F;
    --black6: #646161;
    --black7: #1D1D1D;
    --black8: #282828;
    --black9: #333333;
    --black10: #454545;
    --grey1: #CCCCCC;
    --grey2: #979797;
    --grey3: #828282;
    --grey4: #BDBDBD;
    --grey5: #F7F9FC;
    --grey6: #E0E0E0;
    --grey7: #4F4F4F;
    --gray8: #818181;
    --grey7disabled: #E6E6E6;
    --white1: #FFFFFF;
    --white2: #FCFCFC;
    --red: #F22525;
    --light-pink: #FF8888;
    --dark-pink: #FF3366;
    --purple1: #8088cd;
    --purple2: #666ca4;
    --purple3: #4c517b;
    --purple4: #333652;
    --blue1: #0e4c8a;
    --blue2: #003469;
    --blue3: #5382a1;
    --blue4: #006787;
    --blue5: #c9e3f6;
    --blue6: #00556d;

    --newTabsHover-bg: #edf5fc;


    --orange: #EAA301;
    --green: #00C305;
    --lightgreen: #7cd992;
    --darkyellow: #C04000;
    --red1: #DC143C;
    --red2: #ec5233;
    --red3: #FF0000;
    --red4: #880808;
    --red5: #f34266;
    --red-border-error: #E4292A;
    --cyan1: #088f8f;

    /* NEW UI */
    --pink-primary-color: #ef3279;
    --black-primary-color: #1f1f1f;
    --white-primary-color: #fff;

    --blue-secondary-color: #007b9d;

    --grey-sidebar-color: #f3f3f3;
    --grey-background-color: #fafafa;
    --grey-table-cell-color: #f5f5f5;
    --grey-scrollbar-color: #E6E6E6;

    --black-table-header-color: #505050;

    --draft-status-color: #7cd992;
    --changerequested-status-color: #eba400;
    --inprogress-status-color: #0e4c8a;
    --notstarted-status-color: #ccc;
    --onhold-status-color: #5382a1;
    --production-status-color: #00c305;
    --cancelled-status-color: #f22525;

    --notstarted-phase-color: #ccc;
    --onhold-phase-color: #000;
    --obtaincustomerdataset-phase-color: #707c97;
    --resourceassignment-phase-color: #7cd992;
    --datasetvalidation-phase-color: #eba400;
    --mapping-phase-color: #5382a1;
    --testing-phase-color: #0e4c8a;
    --readyforproduction-phase-color: #088f8f;
    --production-phase-color: #00c305;
    --light-grey: #F3F3F3;
    --black-dark-color: #2E2E2E;


    --nu-ff-roboto: "Roboto", sans-serif;
    /* colors */
    --dt-header-black: #0a0a0a;
    --dt-cell-gray: #636363;
    --gray-border: #e0e0e0;

    --blue-main-color: #007B9D;
    --dark-blue-secondary-color: #006787;
}


/* COLORS */

.pink-primary-color.color {
    color: var(--pink-primary-color) !important;
}

.black-primary-color.color {
    color: var(--black-primary-color) !important;
}

.white-primary-color.color {
    color: var(--white-primary-color) !important;
}

.blue-secondary-color.color {
    color: var(--blue-secondary-color) !important;
}

.yellow-secondary-color.color {
    color: var(--yellow-secondary-color) !important;
}

.baby-blue-secondary-color.color {
    color: var(--baby-blue-secondary-color) !important;
}

.grey1-secondary-color.color {
    color: var(--grey1-secondary-color) !important;
}

.grey2-secondary-color.color {
    color: var(--grey2-secondary-color) !important;
}

.grey3-secondary-color.color {
    color: var(--grey3-secondary-color) !important;
}

.light-grey-color.color {
    color: var(--light-grey) !important;
}

.blue-main-theme.color {
    color: var(--blue-main-theme) !important;
}

.purple.color {
    color: var(--purple) !important;
}

.purple1.color {
    color: var(--purple1) !important;
}

.purple2.color {
    color: var(--purple2) !important;
}

.purple3.color {
    color: var(--purple3) !important;
}

.purple4.color {
    color: var(--purple4) !important;
}

.blue1.color {
    color: var(--blue1) !important;
}

.blue2.color {
    color: var(--blue2) !important;
}

.blue3.color {
    color: var(--blue3) !important;
}

.orange.color {
    color: var(--orange) !important;
}

.green.color {
    color: var(--green) !important;
}

.lightgreen.color {
    color: var(--lightgreen) !important;
}

.cyan.color {
    color: var(--cyan1) !important;
}

.red.color {
    color: var(--red) !important;
}

.dark-pink.color {
    color: var(--dark-pink) !important;
}

.light-pink.color {
    color: var(--light-pink) !important;
}

.grey1.color {
    color: var(--grey1) !important;
}

.grey2.color {
    color: var(--grey2) !important;
}

.grey3.color {
    color: var(--grey3) !important;
}

.grey4.color {
    color: var(--grey4) !important;
}

.grey5.color {
    color: var(--grey5) !important;
}

.grey6.color {
    color: var(--grey6) !important;
}

.grey7.color {
    color: var(--grey7) !important;
}

.grey7disabled.color {
    color: var(--grey7disabled) !important;
}

.black1.color {
    color: var(--black1) !important;
}

.black2.color {
    color: var(--black2) !important;
}

.black3.color {
    color: var(--black3) !important;
}

.black4.color {
    color: var(--black4) !important;
}

.black5.color {
    color: var(--black5) !important;
}

.black6.color {
    color: var(--black6) !important;
}

.black7.color {
    color: var(--black7) !important;
}

.black8.color {
    color: var(--black8) !important;
}

.black9.color {
    color: var(--black9) !important;
}

.black10.color {
    color: var(--black10) !important;
}

.darkyellow.color {
    color: var(--darkyellow) !important;
}

.red1.color {
    color: var(--red1) !important;
}

.red2.color {
    color: var(--red2) !important;
}

.red3.color {
    color: var(--red3) !important;
}

.red4.color {
    color: var(--red4) !important;
}

.red5.color {
    color: var(--red5) !important;
}

.blue-main-color.color {
    color: var(--blue-main-color) !important;
}

.dark-blue-secondary-color.color {
    color: var(--dark-blue-secondary-color) !important;
}

.black-dark-color.color {
    color: var(--black-dark-color) !important;
}

/* BACKGROUND COLORS */
/* BACKGROUND COLORS */

.pink-primary-color.background:not(.status-div) {
    background: var(--pink-primary-color) !important;
}

.black-primary-color.background:not(.status-div) {
    background: var(--black-primary-color) !important;
}

.white-primary-color.background:not(.status-div) {
    background: var(--white-primary-color) !important;
}

.blue-secondary-color.background:not(.status-div) {
    background: var(--blue-secondary-color) !important;
}

.yellow-secondary-color.background:not(.status-div) {
    background: var(--yellow-secondary-color) !important;
}

.baby-blue-secondary-color.background:not(.status-div) {
    background: var(--baby-blue-secondary-color) !important;
}

.grey1-secondary-color.background:not(.status-div) {
    background: var(--grey1-secondary-color) !important;
}

.grey2-secondary-color.background:not(.status-div) {
    background: var(--grey2-secondary-color) !important;
}

.black1.background:not(.status-div) {
    background: var(--black1) !important;
}

.black2.background:not(.status-div) {
    background: var(--black2) !important;
}

.black3.background:not(.status-div) {
    background: var(--black3) !important;
}

.black4.background:not(.status-div) {
    background: var(--black4) !important;
}

.black5.background:not(.status-div) {
    background: var(--black5) !important;
}

.black6.background:not(.status-div) {
    background: var(--black6) !important;
}

.black7.background:not(.status-div) {
    background: var(--black7) !important;
}

.black8.background:not(.status-div) {
    background: var(--black8) !important;
}

.black9.background:not(.status-div) {
    background: var(--black9) !important;
}

.grey1.background:not(.status-div) {
    background: var(--grey1) !important;
}

.grey2.background:not(.status-div) {
    background: var(--grey2) !important;
}

.grey3.background:not(.status-div) {
    background: var(--grey3) !important;
}

.grey4.background:not(.status-div) {
    background: var(--grey4) !important;
}

.grey5.background:not(.status-div) {
    background: var(--grey5) !important;
}

.grey6.background:not(.status-div) {
    background: var(--grey6) !important;
}

.grey7.background:not(.status-div) {
    background: var(--grey7) !important;
}

.light-pink.background:not(.status-div) {
    background: var(--light-pink) !important;
}

.dark-pink.background:not(.status-div) {
    background: var(--dark-pink) !important;
}

.orange.background:not(.status-div) {
    background: var(--orange) !important;
}

.green.background:not(.status-div) {
    background: var(--green) !important;
}

.lightgreen.background:not(.status-div) {
    background: var(--lightgreen) !important;
}

.red.background:not(.status-div) {
    background: var(--red) !important;
}

.purple.background:not(.status-div) {
    background: var(--purple) !important;
}

.purple1.background:not(.status-div) {
    background: var(--purple1) !important;
}

.purple2.background:not(.status-div) {
    background: var(--purple2) !important;
}

.purple3.background:not(.status-div) {
    background: var(--purple3) !important;
}

.purple4.background:not(.status-div) {
    background: var(--purple4) !important;
}

.blue1.background:not(.status-div) {
    background: var(--blue1) !important;
}

.blue2.background:not(.status-div) {
    background: var(--blue2) !important;
}

.blue3.background:not(.status-div) {
    background: var(--blue3) !important;
}

.blue-main-theme.background:not(.status-div) {
    background: var(--blue-main-theme) !important;
}

.darkyellow.background:not(.status-div) {
    background: var(--darkyellow) !important;
}

.red1.background:not(.status-div) {
    background: var(--red1) !important;
}

.red2.background:not(.status-div) {
    background: var(--red2) !important;
}

.red3.background:not(.status-div) {
    background: var(--red3) !important;
}

.red4.background:not(.status-div) {
    background: var(--red4) !important;
}

.red5.background:not(.status-div) {
    background: var(--red5) !important;
}

/* Specific styles for .status-div */

.red5.background.status-div:before {
    background: var(--red5) !important;
}

/* Add the same for each color */
.pink-primary-color.background.status-div:before {
    background: var(--pink-primary-color) !important;
}

.black-primary-color.background.status-div:before {
    background: var(--black-primary-color) !important;
}

.white-primary-color.background.status-div:before {
    background: var(--white-primary-color) !important;
}

.blue-secondary-color.background.status-div:before {
    background: var(--blue-secondary-color) !important;
}

.yellow-secondary-color.background.status-div:before {
    background: var(--yellow-secondary-color) !important;
}

.baby-blue-secondary-color.background.status-div:before {
    background: var(--baby-blue-secondary-color) !important;
}

.grey1-secondary-color.background.status-div:before {
    background: var(--grey1-secondary-color) !important;
}

.grey2-secondary-color.background.status-div:before {
    background: var(--grey2-secondary-color) !important;
}

.black1.background.status-div:before {
    background: var(--black1) !important;
}

.black2.background.status-div:before {
    background: var(--black2) !important;
}

.black3.background.status-div:before {
    background: var(--black3) !important;
}

.black4.background.status-div:before {
    background: var(--black4) !important;
}

.black5.background.status-div:before {
    background: var(--black5) !important;
}

.black6.background.status-div:before {
    background: var(--black6) !important;
}

.black7.background.status-div:before {
    background: var(--black7) !important;
}

.black8.background.status-div:before {
    background: var(--black8) !important;
}

.black9.background.status-div:before {
    background: var(--black9) !important;
}

.grey1.background.status-div:before {
    background: var(--grey1) !important;
}

.grey2.background.status-div:before {
    background: var(--grey2) !important;
}

.grey3.background.status-div:before {
    background: var(--grey3) !important;
}

.grey4.background.status-div:before {
    background: var(--grey4) !important;
}

.grey5.background.status-div:before {
    background: var(--grey5) !important;
}

.grey6.background.status-div:before {
    background: var(--grey6) !important;
}

.grey7.background.status-div:before {
    background: var(--grey7) !important;
}

.light-pink.background.status-div:before {
    background: var(--light-pink) !important;
}

.dark-pink.background.status-div:before {
    background: var(--dark-pink) !important;
}

.orange.background.status-div:before {
    background: var(--orange) !important;
}

.green.background.status-div:before {
    background: var(--green) !important;
}

.lightgreen.background.status-div:before {
    background: var(--lightgreen) !important;
}

.red.background.status-div:before {
    background: var(--red) !important;
}

.purple.background.status-div:before {
    background: var(--purple) !important;
}

.purple1.background.status-div:before {
    background: var(--purple1) !important;
}

.purple2.background.status-div:before {
    background: var(--purple2) !important;
}

.purple3.background.status-div:before {
    background: var(--purple3) !important;
}

.purple4.background.status-div:before {
    background: var(--purple4) !important;
}

.blue1.background.status-div:before {
    background: var(--blue1) !important;
}

.blue2.background.status-div:before {
    background: var(--blue2) !important;
}

.blue3.background.status-div:before {
    background: var(--blue3) !important;
}

.blue-main-theme.background.status-div:before {
    background: var(--blue-main-theme) !important;
}

.darkyellow.background.status-div:before {
    background: var(--darkyellow) !important;
}

.red1.background.status-div:before {
    background: var(--red1) !important;
}

.red2.background.status-div:before {
    background: var(--red2) !important;
}

.red3.background.status-div:before {
    background: var(--red3) !important;
}

.red4.background.status-div:before {
    background: var(--red4) !important;
}