/* .jss2, */
.makeStyles-content-117,
.makeStyles-content-25,
.makeStyles-content-23 {
    margin-left: 30px !important;
}


/* Login Component */

#login {
    height: 100%;
}

.login-first-corner-div {
    min-height: 40px;
    background: var(--pink-primary-color);
    border-bottom-left-radius: 80%;
}

.login-div {
    min-height: 40px;
    background: var(--pink-primary-color);
}

.login-last-corner-div {
    min-height: 40px;
    background: var(--pink-primary-color);
    border-bottom-right-radius: 80%;
}

.login-content {
    padding: 150px 200px;
    text-align: center;
}

.login-content-mfa {
    padding: 200px 200px;
    text-align: center;
}

.splash-screen {
    text-align: center;
    display: grid;
    justify-content: center;
    top: 40%;
    bottom: 40%;
    position: absolute;
    right: 35%;
    left: 35%;
}

.signin-btn {
    background: var(--pink-primary-color) !important;
    color: var(--white1) !important;
    padding: 15px 15px !important;
    width: 100%;
    margin-top: 35px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}


/* Header Component */

#header {
    margin: 0.667rem 0.067rem 0.267rem;
}

#header .input-search {
    background: var(--white2);
    border-radius: 4.167rem;
    padding: 13px;
    width: 33rem;
    margin: auto;
}

.profile-header {
    border: 1px solid #E0E0E0;
    border-radius: 13px;
    min-width: 272px;
}

.profile-header .pink-box {
    background-color: var(--pink-primary-color);
    border-radius: 13px 0 0 13px;
}

.profile-header label {
    font-size: 12px;
    font-weight: 500;
}


/* Sidebar Component */

#sidebar {
    display: flex;
}

.makeStyles-content-2 {
    width: 100%;
}

/* for client view without sidebar */
.ml-0-imp {
    margin-left: 0 !important;
}

.sidebar .MuiListItem-root {
    padding: 0 !important;
}

.sidebar-item {
    position: relative;
}

.sidebar-item.Mui-selected::after {
    content: '';
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    justify-content: center;
    background: var(--pink-primary-color);
    border: 3px solid var(--pink-primary-color);
    border-radius: 2px;
}


/* Pagination */

.pagination {
    justify-content: flex-end;
}

.pagination-arrow {
    color: var(--black2) !important;
}

.pagination-arrow.Mui-disabled {
    color: rgb(66, 80, 92, 0.5) !important;
}


/* NEW UI */

main {
    margin: 40px 99px;
    width: calc(100% - 145px);
    margin-top: 105px;
    overflow-x: hidden;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 1200px) and (max-width: 1600px) {
    main {
        margin: 40px 40px;
        width: calc(100% - 145px);
        margin-top: 105px;
        overflow-x: hidden;
        padding-left: 15px;
        padding-right: 15px;
    }
}

header .MuiToolbar-gutters {
    padding-right: 0;
    padding-left: 0;
}

.avatar-navbar {
    margin-left: auto;
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-navbar:hover {
    background: #2f2d34;
}

.avatar-navbar.selected {
    background: #3e3c47;
}

.header-user-avatar {
    border: 1.50px solid #ccc;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #ccc;
    align-content: center;
}

.avatar-navbar:hover .header-user-avatar,
.avatar-navbar.selected .header-user-avatar {
    border: 1px solid #5e5e5e;
    background: #e0e0e0;
    color: #5e5e5e;
}

.header-logo {
    height: 64px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-logo:hover {
    background: #2f2d34;
}

.sidebar {
    border-right: 1px solid #f3f3f3;
    background: var(--grey-sidebar-color);
    height: 100%;
    padding-top: 0 !important;
    position: fixed;
    z-index: 1000;
}

.drawer-header {
    background: var(--grey-sidebar-color);
}

.list-item-text .MuiTypography-root {
    font-family: var(--font-family);
    font-weight: 350;
    font-size: 12px;
    text-align: center;
    color: #4E4E4E;
}

.Mui-selected .list-item-text .MuiTypography-root {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: var(--pink-primary-color) !important;
}

.list-item-btn:hover {
    background-color: #fff !important;
}

.list-item-btn.Mui-selected {
    background-color: #fff !important;
    border-left: 3px solid var(--pink-primary-color);
}

.list-item-btn.Mui-selected .MuiListItemIcon-root {
    color: var(--pink-primary-color) !important;
}

body ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    /* width of the entire scrollbar */
}

body ::-webkit-scrollbar-thumb {
    background-color: var(--grey-scrollbar-color);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}

.content-card-paper {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid #e0e0e0;
    margin-top: 2rem;
    padding: 4rem;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    /* background-color: #f8f8f8; */
    margin-top: 40px;
}

.footer-content {
    display: flex;
    place-content: center;
}

.copyright {
    margin-left: 10px;
    font-weight: 350;
    color: #5e5e5e;
}

.truncate-container {
    position: relative !important;
}

div.truncate-content {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: normal !important;
}


.truncate-ellipsis {
    margin-top: 8px;
    font-size: 0.875rem;
    color: #2c87f0;
}