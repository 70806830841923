/* BACKGROUND COLORS */

.pink-primary-color.background:not(.status-div) {
    background: var(--pink-primary-color) !important;
}

.black-primary-color.background:not(.status-div) {
    background: var(--black-primary-color) !important;
}

.white-primary-color.background:not(.status-div) {
    background: var(--white-primary-color) !important;
}

.blue-secondary-color.background:not(.status-div) {
    background: var(--blue-secondary-color) !important;
}

.yellow-secondary-color.background:not(.status-div) {
    background: var(--yellow-secondary-color) !important;
}

.baby-blue-secondary-color.background:not(.status-div) {
    background: var(--baby-blue-secondary-color) !important;
}

.grey1-secondary-color.background:not(.status-div) {
    background: var(--grey1-secondary-color) !important;
}

.grey2-secondary-color.background:not(.status-div) {
    background: var(--grey2-secondary-color) !important;
}

.black1.background:not(.status-div) {
    background: var(--black1) !important;
}

.black2.background:not(.status-div) {
    background: var(--black2) !important;
}

.black3.background:not(.status-div) {
    background: var(--black3) !important;
}

.black4.background:not(.status-div) {
    background: var(--black4) !important;
}

.black5.background:not(.status-div) {
    background: var(--black5) !important;
}

.black6.background:not(.status-div) {
    background: var(--black6) !important;
}

.black7.background:not(.status-div) {
    background: var(--black7) !important;
}

.black8.background:not(.status-div) {
    background: var(--black8) !important;
}

.black9.background:not(.status-div) {
    background: var(--black9) !important;
}

.grey1.background:not(.status-div) {
    background: var(--grey1) !important;
}

.grey2.background:not(.status-div) {
    background: var(--grey2) !important;
}

.grey3.background:not(.status-div) {
    background: var(--grey3) !important;
}

.grey4.background:not(.status-div) {
    background: var(--grey4) !important;
}

.grey5.background:not(.status-div) {
    background: var(--grey5) !important;
}

.grey6.background:not(.status-div) {
    background: var(--grey6) !important;
}

.grey7.background:not(.status-div) {
    background: var(--grey7) !important;
}

.light-pink.background:not(.status-div) {
    background: var(--light-pink) !important;
}

.dark-pink.background:not(.status-div) {
    background: var(--dark-pink) !important;
}

.orange.background:not(.status-div) {
    background: var(--orange) !important;
}

.green.background:not(.status-div) {
    background: var(--green) !important;
}

.lightgreen.background:not(.status-div) {
    background: var(--lightgreen) !important;
}

.red.background:not(.status-div) {
    background: var(--red) !important;
}

.purple.background:not(.status-div) {
    background: var(--purple) !important;
}

.purple1.background:not(.status-div) {
    background: var(--purple1) !important;
}

.purple2.background:not(.status-div) {
    background: var(--purple2) !important;
}

.purple3.background:not(.status-div) {
    background: var(--purple3) !important;
}

.purple4.background:not(.status-div) {
    background: var(--purple4) !important;
}

.blue1.background:not(.status-div) {
    background: var(--blue1) !important;
}

.blue2.background:not(.status-div) {
    background: var(--blue2) !important;
}

.blue3.background:not(.status-div) {
    background: var(--blue3) !important;
}

.blue-main-theme.background:not(.status-div) {
    background: var(--blue-main-theme) !important;
}

.darkyellow.background:not(.status-div) {
    background: var(--darkyellow) !important;
}

.red1.background:not(.status-div) {
    background: var(--red1) !important;
}

.red2.background:not(.status-div) {
    background: var(--red2) !important;
}

.red3.background:not(.status-div) {
    background: var(--red3) !important;
}

.red4.background:not(.status-div) {
    background: var(--red4) !important;
}

.red5.background:not(.status-div) {
    background: var(--red5) !important;
}

/* Specific styles for .status-div */

.red5.background.status-div::before {
    background: var(--red5) !important;
}

/* Add the same for each color */
.pink-primary-color.background.status-div::before {
    background: var(--pink-primary-color) !important;
}

.black-primary-color.background.status-div::before {
    background: var(--black-primary-color) !important;
}

.white-primary-color.background.status-div::before {
    background: var(--white-primary-color) !important;
}

.blue-secondary-color.background.status-div::before {
    background: var(--blue-secondary-color) !important;
}

.yellow-secondary-color.background.status-div::before {
    background: var(--yellow-secondary-color) !important;
}

.baby-blue-secondary-color.background.status-div::before {
    background: var(--baby-blue-secondary-color) !important;
}

.grey1-secondary-color.background.status-div::before {
    background: var(--grey1-secondary-color) !important;
}

.grey2-secondary-color.background.status-div::before {
    background: var(--grey2-secondary-color) !important;
}

.black1.background.status-div::before {
    background: var(--black1) !important;
}

.black2.background.status-div::before {
    background: var(--black2) !important;
}

.black3.background.status-div::before {
    background: var(--black3) !important;
}

.black4.background.status-div::before {
    background: var(--black4) !important;
}

.black5.background.status-div::before {
    background: var(--black5) !important;
}

.black6.background.status-div::before {
    background: var(--black6) !important;
}

.black7.background.status-div::before {
    background: var(--black7) !important;
}

.black8.background.status-div::before {
    background: var(--black8) !important;
}

.black9.background.status-div::before {
    background: var(--black9) !important;
}

.grey1.background.status-div::before {
    background: var(--grey1) !important;
}

.grey2.background.status-div::before {
    background: var(--grey2) !important;
}

.grey3.background.status-div::before {
    background: var(--grey3) !important;
}

.grey4.background.status-div::before {
    background: var(--grey4) !important;
}

.grey5.background.status-div::before {
    background: var(--grey5) !important;
}

.grey6.background.status-div::before {
    background: var(--grey6) !important;
}

.grey7.background.status-div::before {
    background: var(--grey7) !important;
}

.light-pink.background.status-div::before {
    background: var(--light-pink) !important;
}

.dark-pink.background.status-div::before {
    background: var(--dark-pink) !important;
}

.orange.background.status-div::before {
    background: var(--orange) !important;
}

.green.background.status-div::before {
    background: var(--green) !important;
}

.lightgreen.background.status-div::before {
    background: var(--lightgreen) !important;
}

.red.background.status-div::before {
    background: var(--red) !important;
}

.purple.background.status-div::before {
    background: var(--purple) !important;
}

.purple1.background.status-div::before {
    background: var(--purple1) !important;
}

.purple2.background.status-div::before {
    background: var(--purple2) !important;
}

.purple3.background.status-div::before {
    background: var(--purple3) !important;
}

.purple4.background.status-div::before {
    background: var(--purple4) !important;
}

.blue1.background.status-div::before {
    background: var(--blue1) !important;
}

.blue2.background.status-div::before {
    background: var(--blue2) !important;
}

.blue3.background.status-div::before {
    background: var(--blue3) !important;
}

.blue-main-theme.background.status-div::before {
    background: var(--blue-main-theme) !important;
}

.darkyellow.background.status-div::before {
    background: var(--darkyellow) !important;
}

.red1.background.status-div::before {
    background: var(--red1) !important;
}

.red2.background.status-div::before {
    background: var(--red2) !important;
}

.red3.background.status-div::before {
    background: var(--red3) !important;
}

.red4.background.status-div::before {
    background: var(--red4) !important;
}

/* Status div styles */
.status-div {
    border-radius: 4px;
    padding: 6px 2px;
    font-weight: 500;
    font-size: smaller;
    color: var(--black10);
    font-size: 11px;
    position: relative;
    padding-left: 16px;
}

div.status-div {
    background: transparent !important;
}

.status-div::before,
.phase-div::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 0;
}

/* Group Request Status:
1- Active
2- Cancelled
3- Change Requested
4- Draft */

.status-div.active::before {
    background-color: var(--green);
}

.status-div.changeRequested::before {
    background-color: var(--grey1-secondary-color);
}

.status-div.draft::before {
    background-color: var(--yellow-secondary-color);
}

/* Connectivity Status:
1- In Progress
2- On Hold
3- Blocked
4- Cancelled
5- Not Started
6- Production
7- Production Disabled */

.status-div.inProgress::before {
    background-color: var(--blue1);
}

.status-div.onHold::before {
    background-color: var(--blue3);
}

.status-div.blocked::before {
    background-color: var(--yellow-secondary-color);
}

.status-div.cancelled::before {
    background-color: var(--red);
}

.status-div.notStarted::before {
    background-color: var(--grey1);
}

.status-div.production::before {
    background-color: var(--green);
}

.status-div.productionDisabled::before {
    background-color: var(--lightgreen);
}

.status-div.pendingg::before {
    background-color: var(--lightgreen);
}

/* Connectivity Phases:
1- Requirements Gathering
2- Obtain Customer Dataset
3- Resource Assignment
4- Dataset Validation
5- Mapping
6- Testing
7- Production */

.phase-div {
    border-radius: 4px;
    padding: 6px 2px;
    font-weight: 500;
    font-size: smaller;
    color: var(--black10);
    padding-left: 16px;
    position: relative;
    font-size: 11px;
}


.phase-div.notStarted::before {
    background-color: var(--grey1);
}

.phase-div.requirementGathering::before {
    background-color: var(--black-primary-color);
}

.phase-div.obtainCustomerDataset::before {
    background-color: var(--black4);
}

.phase-div.resourceAssignment::before {
    background-color: var(--lightgreen);
}

.phase-div.datasetValidation::before {
    background-color: var(--yellow-secondary-color);
}

.phase-div.mapping::before {
    background-color: var(--blue3);
}

.phase-div.testing::before {
    background-color: var(--blue1);
}

.phase-div.readyforproduction::before {
    background-color: var(--cyan1);
}

.phase-div.production::before {
    background-color: var(--green);
}

.phase-div.onHold::before {
    background-color: var(--blue3);
}

.phase-div.inProgress::before {
    background-color: var(--blue1);
}

.phase-div.cancelled::before {
    background-color: var(--red);
}

/* Feed Execution Status:
1- In Progress
2- Completed
3- Pass
4- Fail
5- Failed Parsing
6- Failed Tracking
7- Failed Mapping
8- Failed Serializing
9- Failed Encrypting
10- Failed Transmitting */

#transmissions-statistics .status-div.completed::before {
    background-color: var(--green);
}

.status-div.completed::before {
    background-color: var(--blue3);
}

.status-div.pass::before {
    background-color: var(--green);
}

.status-div.fail::before {
    background-color: var(--red);
}

.status-div.pendingTransmission::before {
    background-color: var(--yellow-secondary-color);
}

.status-div.pending::before {
    background-color: var(--purple1);
}

.status-div.filePullingFailed::before {
    background-color: var(--red);
}

.status-div.failedParsing::before {
    background-color: var(--red5);
}

.status-div.failedTracking::before {
    background-color: var(--darkyellow);
}

.status-div.finished::before {
    background-color: var(--lightgreen);
}

.status-div.failedMapping::before {
    background-color: var(--red1);
}

.status-div.failedSerializing::before {
    background-color: var(--red2);
}

.status-div.failedEncrypting::before {
    background-color: var(--red3);
}

.status-div.failedTransmitting::before {
    background-color: var(--red4);
}

/* Feed Processing Steps:
1- Parser
2- Tracker
3- Mapper
4- Serializer
5- Encrypter
6- Transmitter */

.status-div.parser::before {
    background-color: var(--blue3);
}

.status-div.tracker::before {
    background-color: var(--darkyellow);
}

.status-div.mapper::before {
    background-color: var(--yellow-secondary-color);
}

.status-div.serializer::before {
    background-color: var(--grey1);
}

.status-div.encrypter::before {
    background-color: var(--grey7);
}

.status-div.transmitter::before {
    background-color: var(--blue1);
}